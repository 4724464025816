html {
  scroll-behavior: smooth;
}

::selection {
  background-color: theme-color('primary');
  color: color-yiq(theme-color('primary'));

  .bg-primary & {
    background-color: theme-color('dark');
  }
}

[width='1'][height='1'] {
  position: absolute;
  width: 0;
  height: 0;
}

[role='button'] {
  cursor: pointer;
}
