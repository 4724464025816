$gray-disclaimer: #999;
$opacity-disclaimer: .85;

.modal-disclaimer {
  background:
  linear-gradient(
    135deg,
    rgba($white, $opacity-disclaimer) 25%,
    rgba($gray-disclaimer, $opacity-disclaimer) 25%, rgba($gray-disclaimer, $opacity-disclaimer) 50%,
    rgba($white, $opacity-disclaimer) 50%, rgba($white, $opacity-disclaimer) 75%,
    rgba($gray-disclaimer, $opacity-disclaimer) 75%
  );

  background-size: 7px 7px;
}
