.header {
  background-color: $light;

  &__container {
    @include make-container();
    @include make-container-max-widths();

    align-items: center;
    justify-content: space-between;

    @include media-breakpoint-up (md) {
      display: flex;
    }
  }

  &__logo {
    //  mb-3 mb-md-0
    @include img-fluid();

    display: block;
    margin-right: auto;
    margin-bottom: map-get($spacers, 3);
    margin-left: auto;

    @include media-breakpoint-up (md) {
      margin-right: 0;
      margin-bottom: 0;
      margin-left: 0;
    }
  }
}
