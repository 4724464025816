.grid {
  display: grid;
  justify-content: space-around;
  padding: map-get($spacers, 3);
  grid-gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(70px, 1fr));


  &__item {
    @include img-fluid();
  }
}
