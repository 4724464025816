.custom-list {
  margin: map-get($spacers, 4) 0;
  padding-left: 0;
  list-style: none;

  &__item {
    padding-left: 2em;
    text-indent: -1em;

    &::before {
      @extend %fa-icon; // sass-lint:disable-line no-extends
      @extend .fas; // sass-lint:disable-line no-extends, placeholder-in-extend

      padding-right: .3rem;
      color: $green;
      content: fa-content($fa-var-check);
    }
  }
}
